export const PHONEREGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const PASSWORDREGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/


export const PANCARD = "[A-Z]{5}[0-9]{4}[A-Z]{1}"

// export const UPI_ID = [a-zA-Z0-9\.\-]{2,256}\@[a-zA-Z][a-zA-Z]{2,64}/

export const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/

export const bandAccountRegex = /^\d{9,18}$/
