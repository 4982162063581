// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

// const ROOTS_ADMIN = '/dashboard';
const ROOTS_ADMIN = '/admin';
const BLOG_DOMAIN = 'https://blog.impaac.org';
const ROOT_IMPAAC = 'https://www.impaac.org';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
};

export const BLOG_PATH = {
  root: BLOG_DOMAIN,
  blog: path(BLOG_DOMAIN, '/story'),
  faqs: path(BLOG_DOMAIN, '/faq'),
  privacypolicy: path(BLOG_DOMAIN, '/privacy-policy'),
  termsAndconditions: path(BLOG_DOMAIN, '/terms-conditions'),
  howItWorks: path(BLOG_DOMAIN, '/how-it-works'),
  contact: path(BLOG_DOMAIN, '/contact'),
};
export const PATH_PAGE = {
  campaignView: (id, title) => `/fundraisers/${title}/${id}`,
  paymentSuccessful: (id) => `/donation-successful/${id}`,
  paymentClaim: (id) => `/donation-claim/${id}`,
  campaignNew: '/fundraisers/new',
  campaignEdit: (id, title) => `/fundraisers/${title}/${id}/edit`,
  maintenance: '/maintenance',
  about: '/about',
  contact: '/contact',
  faqs: '/faqs',
  blog: '/story',
  howItWorks: '/how-it-works',
  linktree: '/linktree',
  privacypolicy: '/privacy-policy',
  // page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_ADMIN,
  general: {
    app: path(ROOTS_ADMIN, '/app'),
    donationexpense: path(ROOTS_ADMIN, '/donationexpense'),
    mydashboard: path(ROOTS_ADMIN, '/mydashboard'),
    campaign: path(ROOTS_ADMIN, '/campaign'),
    ngo: path(ROOTS_ADMIN, '/ngo'),
    donation: path(ROOTS_ADMIN, '/donation'),
    donationdraft: path(ROOTS_ADMIN, '/donationdraft'),
    userkyc: path(ROOTS_ADMIN, '/userkyc'),
    withdrawal: path(ROOTS_ADMIN, '/withdrawal'),
    kyc: path(ROOTS_ADMIN, '/kyc'),
    users: path(ROOTS_ADMIN, '/users'),
    enquiry: path(ROOTS_ADMIN, '/enquiry'),
    booking: path(ROOTS_ADMIN, '/booking'),
    page403: path(ROOTS_ADMIN, '/403'),
  },
  campaign: {
    root: path(ROOTS_ADMIN, '/campaign'),
    list: path(ROOTS_ADMIN, '/campaign/list'),
    view: (id) => path(ROOTS_ADMIN, `/campaign/${id}`),
  },
  ngo: {
    root: path(ROOTS_ADMIN, '/ngo'),
    list: path(ROOTS_ADMIN, '/ngo/list'),
    view: (id) => path(ROOTS_ADMIN, `/ngo/${id}`),
  },
  donation: {
    root: path(ROOTS_ADMIN, '/donation'),
    list: path(ROOTS_ADMIN, '/donation/list'),
    view: (id) => path(ROOTS_ADMIN, `/donation/${id}`),
  },
  donationdraft: {
    root: path(ROOTS_ADMIN, '/donationdraft'),
    list: path(ROOTS_ADMIN, '/donationdraft/list'),
    view: (id) => path(ROOTS_ADMIN, `/donationdraft/${id}`),
  },
  userkyc: {
    root: path(ROOTS_ADMIN, '/userkyc'),
    list: path(ROOTS_ADMIN, '/userkyc/list'),
    view: (id) => path(ROOTS_ADMIN, `/userkyc/${id}`),
  },
  withdrawal: {
    root: path(ROOTS_ADMIN, '/withdrawal'),
    list: path(ROOTS_ADMIN, '/withdrawal/list'),
    view: (id) => path(ROOTS_ADMIN, `/withdrawal/${id}`),
  },
  kyc: {
    root: path(ROOTS_ADMIN, '/kyc'),
    list: path(ROOTS_ADMIN, '/kyc/list'),
    view: (id) => path(ROOTS_ADMIN, `/kyc/${id}`),
  },
  users: {
    root: path(ROOTS_ADMIN, '/users'),
    list: path(ROOTS_ADMIN, '/users/list'),
    view: (id) => path(ROOTS_ADMIN, `/users/${id}`),
  },
  enquiry: {
    root: path(ROOTS_ADMIN, '/enquiry'),
    list: path(ROOTS_ADMIN, '/enquiry/list'),
    view: (id) => path(ROOTS_ADMIN, `/enquiry/${id}`),
  },
  user: {
    root: path(ROOTS_ADMIN, '/user'),
    profile: path(ROOTS_ADMIN, '/user/profile'),
    account: path(ROOTS_ADMIN, '/user/account'),
    campaigns: path(ROOTS_ADMIN, '/user/campaigns'),
    ngos: path(ROOTS_ADMIN, '/user/ngos'),
    donations: path(ROOTS_ADMIN, '/user/donations'),
  },
  organisation: {
    root: path(ROOTS_ADMIN, '/organisation'),
    profile: path(ROOTS_ADMIN, '/organisation/profile'),
    account: path(ROOTS_ADMIN, '/organisation/account'),
    new: path(ROOTS_ADMIN, '/organisation/new'),
  },
};

// export const PATH_ADMIN = {
//   campaignViewAdmin: (id, title) => path(ROOTS_ADMIN, `/campaign/${id}`),
// }

export const PATH_DOCS = 'https://impaac.org/';

export const PATH_IMPAAC = {
  root: ROOT_IMPAAC,
  campaignView: (id, title) => path(ROOT_IMPAAC, `/fundraisers/${title}/${id}`),
  campaignEdit: (id, title) => path(ROOT_IMPAAC, `/fundraisers/${title}/${id}/edit`),
};
