// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  cart: getIcon('ic_cart'),
  user: getIcon('ic_user'),
  banking: getIcon('ic_banking'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

// Get item from local storage.
const storedAccessObjectString = localStorage.getItem('accessObject');
const getUserRole = localStorage?.getItem('user_role');
const storedAccess = JSON.parse(storedAccessObjectString);
const userRole = JSON.parse(getUserRole);

const dashboard =
  storedAccess?.dashboard === true
    ? { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }
    : null;

const donationexpense =
  storedAccess?.dashboard === true
    ? { title: 'Donation Expense', path: PATH_DASHBOARD.general.donationexpense, icon: ICONS.dashboard }
    : null;

const mydashboard =
  // storedAccess?.dashboard === true
     userRole?.coordinator === true
     ? { title: 'My Dashboard', path: PATH_DASHBOARD.general.mydashboard, icon: ICONS.user }
    : null;

const donordraft =
  storedAccess?.donation === true
    ? {
        title: 'Donations',
        path: PATH_DASHBOARD.general.donationdraft,
        icon: ICONS.ecommerce,
      }
    : null;

const campaigns =
  storedAccess?.campaign === true
    ? {
        title: 'Campaigns',
        path: PATH_DASHBOARD.general.campaign,
        icon: ICONS.menuItem,
      }
    : null;

const ngos =
  storedAccess?.ngo === true
    ? {
        title: 'NGO KYC',
        path: PATH_DASHBOARD.general.ngo,
        icon: ICONS.banking,
      }
    : null;

const userkyc =
  storedAccess?.user === true
    ? {
        title: 'User KYC',
        path: PATH_DASHBOARD.general.userkyc,
        icon: ICONS.banking,
      }
    : null;

const withdrawals =
  storedAccess?.withdrawal === true
    ? {
        title: 'Withdrawals',
        path: PATH_DASHBOARD.general.withdrawal,
        icon: ICONS.analytics,
      }
    : null;

const users =
  storedAccess?.user === true
    ? {
        title: 'Users',
        path: PATH_DASHBOARD.general.users,
        icon: ICONS.user,
      }
    : null;

const enquiry =
  storedAccess?.user === true
    ? {
        title: 'Enquiry',
        path: PATH_DASHBOARD.general.enquiry,
        icon: ICONS.user,
      }
    : null;

const donor =
  storedAccess?.donor === true
    ? {
        title: 'Donor',
        path: PATH_DASHBOARD.general.donation,
        icon: ICONS.ecommerce,
      }
    : null;

export const adminNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'Campaigns', path: PATH_DASHBOARD.general.campaign, icon: ICONS.dashboard },
      { title: 'NGOs', path: PATH_DASHBOARD.general.ngo, icon: ICONS.dashboard },
      { title: 'Donor Draft', path: PATH_DASHBOARD.general.donationdraft, icon: ICONS.ecommerce },
      // { title: 'Donor', path: PATH_DASHBOARD.general.donation, icon: ICONS.ecommerce },
      // { title: 'Withdrawals', path: PATH_DASHBOARD.general.withdrawal, icon: ICONS.analytics },
      { title: 'KYC', path: PATH_DASHBOARD.general.kyc, icon: ICONS.banking },
      { title: 'Users', path: PATH_DASHBOARD.general.users, icon: ICONS.user },
      { title: 'Enquiry', path: PATH_DASHBOARD.general.enquiry, icon: ICONS.user },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'My Profile',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.user.profile },
          { title: 'account', path: PATH_DASHBOARD.user.account },
          { title: 'my Referral Campaigns', path: PATH_DASHBOARD.user.campaigns },
          { title: 'my Referral NGOs', path: PATH_DASHBOARD.user.ngos },
          { title: 'my Referral Donations', path: PATH_DASHBOARD.user.donations },
        ],
      },
    ],
  },
];

export const superAdminNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [dashboard, mydashboard, campaigns, donordraft, withdrawals, ngos, userkyc, users, donor], // enquiry,
  },
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'My Profile',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
          { title: 'my Referral Campaigns', path: PATH_DASHBOARD.user.campaigns },
          // { title: 'my Referral NGOs', path: PATH_DASHBOARD.user.ngos },
          // { title: 'my Referral Donations', path: PATH_DASHBOARD.user.donations },
        ],
      },
    ],
  },
];
